<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    height="64"
    width="64"
    viewBox="0 0 64 64"
    fill="currentColor"
    stroke-width="0px"
  >
    <g>
      <path
        d="M44.5,59h-25c-8,0-14.5-6.5-14.5-14.5v-25c0-8,6.5-14.5,14.5-14.5h25c8,0,14.5,6.5,14.5,14.5v25c0,8-6.5,14.5-14.5,14.5ZM19.5,9c-5.8,0-10.5,4.7-10.5,10.5v25c0,5.8,4.7,10.5,10.5,10.5h25c5.8,0,10.5-4.7,10.5-10.5v-25c0-5.8-4.7-10.5-10.5-10.5h-25Z"
      />
      <path d="M45.9,14.8c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3,3.3-1.5,3.3-3.3-1.5-3.3-3.3-3.3Z" />
    </g>
    <path
      d="M32,20c-6.6,0-12,5.4-12,12s5.4,12,12,12,12-5.4,12-12-5.4-12-12-12ZM32,40c-4.4,0-8-3.6-8-8s3.6-8,8-8,8,3.6,8,8-3.6,8-8,8Z"
    />
  </svg>
</template>
